import clsx from 'clsx';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React, { useEffect } from 'react';

import { Layout, SEO } from '../../../components';
import NavigateToSearch from '../../../components/search/navigate-to-search';
import './index.scss';

const YSOForBusyParents = ({
  data: {
    authorImage,
    personUsingGoogleImage,
    happyPersonImage,
    busyParentImage,
    peopleStudyingImage,
  },
}) => {
  const pointClassName = 'p-4 mb-20';
  const containerClassName =
    'container h-full flex flex-col justify-around overflow-hidden m-auto max-w-xl';
  const headerClassName = 'text-3xl mb-4';
  const paragraphClassName = 'text-xl';
  const sectionImageClassName = 'max-w-xl m-auto lg:mb-12';

  useEffect(() => {
    requestAnimationFrame(() => window.scrollTo(0, 0));
  }, []);

  return (
    <Layout bodyClass="for-busy-parents text-left" lpMode>
      <SEO title="Made for Busy Parents" url="/lp/for-busy-parents/" />

      <section className="h-screen p-4">
        <div className="container max-w-xl m-auto">
          <h1 className="text-black">YOU Are Safe Online</h1>

          <div className="mt-32 w-full flex flex-col items-center">
            <Img
              className="rounded-full"
              alt="You Are Safe Online's author"
              fixed={authorImage.childImageSharp.fixed}
            />
            <p className="mt-4 text-2xl">
              I help{' '}
              <span className="text-secondary font-bold">busy parents</span>{' '}
              take care of their children by providing{' '}
              <span className="text-secondary font-bold">
                internet safety advice
              </span>
              .
            </p>
          </div>
          <p className="mt-12">
            Try searching for what is bothering you, and you'll get your answer
            in no time.
          </p>
          <div className="flex items-end mt-8 w-full">
            <NavigateToSearch className="w-full" />
          </div>
        </div>
      </section>

      <section className="bg-primary-100 py-10">
        <BackgroundImage
          Tag="div"
          className={clsx(sectionImageClassName, 'h-48 lg:h-64')}
          fluid={busyParentImage.childImageSharp.fluid}
        >
          &nbsp;
        </BackgroundImage>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <div>
              <h2 className={headerClassName}>You are a busy parent.</h2>
              <p className={paragraphClassName}>
                Being a parent is a very hard job on its own. But it does not
                stop there, does it? There are a lot of obligations you have to
                take care of like paying the bills, cleaning, shopping, and
                trying to maintain a work-life balance. On top of it, add some
                free time for yourself (because you deserve it), and, boom! The
                day ended.
              </p>
            </div>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              You want to keep your family safe.
            </h2>
            <p className={paragraphClassName}>
              Whether it's about making sure your home is protected against
              thieves, or the school is treating your kids fairly, it's your job
              to make sure your family is safe. Now more than ever, this also
              applies to the internet. All of your family members use the
              internet daily and you know there are a lot of dangers there.
              Making sure they are safe while using the laptop or the mobile
              phone is as important as making sure to lock the door when you
              leave home.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              You don't want to waste time learning useless things.
            </h2>
            <p className={paragraphClassName}>
              Internet safety is no joke, but learning how to do it efficiently
              takes a lot of time. It took me a master's degree to understand
              why Public WiFi can be dangerous (and how to fix this). Just
              imagine all the computer slang involved. Who has time for all of
              this?
            </p>
          </div>
        </div>
      </section>

      <section className="py-10">
        <BackgroundImage
          Tag="div"
          className={clsx(sectionImageClassName, 'h-48')}
          fluid={personUsingGoogleImage.childImageSharp.fluid}
        >
          &nbsp;
        </BackgroundImage>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              Do you google your internet safety questions?
            </h2>
            <p className={paragraphClassName}>
              Most people do. All the information is out there (and for free).
              It takes time to find the answer you are looking for. Also, what
              you find is general advice like "don't go on shady websites", but
              how do you know what a "shady website" is, anyway?
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              Do you ask your friends for internet safety advice?
            </h2>
            <p className={paragraphClassName}>
              A fast way to get an answer is to ask your friend a question. How
              good that answer is depends on how knowledgeable your friend is in
              that area. I'm not saying that they don't have the best
              intentions. On contrary, I believe friends always want to help us
              and they try their best. I never heard a friend say "I don't know
              if you need a firewall". They usually say something like: "I don't
              have a firewall and I didn't have any problems so far". How
              comfortable are you gambling your family's safety like this?
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              Do you ask on Quora, Reddit, or other forums for internet safety
              advice?
            </h2>
            <p className={paragraphClassName}>
              This is the place where a lot of knowledgeable people live. Asking
              your questions here will get you a lot of sensible advice. There
              is just one problem: do you understand this? Tech people tend to
              use very techy words. You need a university degree just to
              understand them. Also, how do you know that someone isn't trolling
              you? How do you know if they are not giving you bogus advice to
              make fun of you?
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              Do you just wing it when it comes to internet safety?
            </h2>
            <p className={paragraphClassName}>
              Usually, this works well enough. Since not every move you make is
              followed by a hacker, most of your "not safe online" actions get a
              free pass. The question is not{' '}
              <span className="text-secondary font-bold">
                "will you get hacked?"
              </span>
              , but{' '}
              <span className="text-secondary font-bold">
                "when will you get hacked?"
              </span>
              . It takes a second to use your credit card on the wrong website
              and lose all the money you have on it. When you wing it, your kids
              also wing it. How do you know they are making the right choices?
            </p>
          </div>
        </div>
      </section>

      <section className="bg-primary-100 py-10">
        <BackgroundImage
          Tag="div"
          className={clsx(sectionImageClassName, 'h-56 lg:h-64')}
          fluid={happyPersonImage.childImageSharp.fluid}
        >
          &nbsp;
        </BackgroundImage>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              In a perfect world, internet safety advice would be written for
              NON-technical people.
            </h2>
            <p className={paragraphClassName}>
              There is a lot of literature about internet safety, but most
              people simply don't have the scientific background to read it. All
              the information is out there, it's just in the "wrong language".
              Ideally, all of it would be translated into{' '}
              <span className="text-secondary font-bold">
                "human-friendly words"
              </span>
              so we could all have access to it. Like watching a movie with
              subtitles. Really helpful if you don't understand the language
              they are speaking.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              In a perfect world, internet safety advice would be specific and
              actable.
            </h2>
            <p className={paragraphClassName}>
              Imagine asking{' '}
              <Link to="/blog/how-to-hide-last-seen-on-facebook">
                "how to hide Facebook last seen on?"
              </Link>{' '}
              and quickly get a list of actable steps. Or asking{' '}
              <Link to="/blog/can-you-see-old-facebook-passwords">
                "can I see old facebook passwords?"
              </Link>{' '}
              and get a quick yes or no with a detailed explanation if you want
              to read more about it.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              In a perfect world, internet safety advice comes from a place
              specialized in internet safety.
            </h2>
            <p className={paragraphClassName}>
              The best place to buy a laptop is from a computer shop. Likewise,
              the best place to search for internet safety advice is to ask on a
              website that is specialized in internet safety. This sounds like a
              no-brainer, but more often than not we take advice from shady
              sources, without double-checking it.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              In a perfect world, internet safety advice is free and available
              24/7.
            </h2>
            <p className={paragraphClassName}>
              If your kid gets locked out of their Facebook, you need a solution
              now. You should not wait for your friend to make time for you, nor
              should you pay expensive consultants to help you. Ideally, all the
              help and advice you need is free and accessible in seconds.
            </p>
          </div>
        </div>
      </section>

      <section className="py-10">
        <BackgroundImage
          Tag="div"
          className={clsx(sectionImageClassName, 'h-48')}
          fluid={peopleStudyingImage.childImageSharp.fluid}
        >
          &nbsp;
        </BackgroundImage>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              I believe you should not have to take an IT class just to be able
              to keep yourself and your family safe online.
            </h2>
            <p className={paragraphClassName}>
              If this is something you are interested in, go ahead. But if
              computer science is not your interest there should be a way for
              you to stay safe online without wasting your time. Online safety
              advice should be accessible to everybody.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              There should be a place dedicated to online safety.
            </h2>
            <p className={paragraphClassName}>
              A place where people could ask their questions and receive simple
              and actable advice instantly. No more reaching aimlessly on
              Google, no more relying on other people, no more guessing. I
              believe you should have free and 24/7 access to a library of
              knowledge about internet safety.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              The more people know how to stay safe online, the less profitable
              the "hacking business" gets.
            </h2>
            <p className={paragraphClassName}>
              Like everything in this world,{' '}
              <span className="text-secondary font-bold">
                hacking is about money
              </span>
              . The reason so many hackers exist is that it's profitable. There
              are, simply, a lot of easy targets online. However, there is hope!
              If we learn basic internet safety, the balance will change. The
              more people learn to avoid hackers, the harder it is for them to
              find a victim. This will increase their costs and slowly make the
              "hacking business" less profitable.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-emp py-10 h-screen flex flex-col justify-around">
        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              Introducing You Are Safe Online!
            </h2>
            <p className={paragraphClassName}>
              You Are Safe Online provides{' '}
              <span className="text-secondary font-bold">
                internet safety advice
              </span>{' '}
              for{' '}
              <span className="text-secondary font-bold">
                non-technical people
              </span>
              .
            </p>
          </div>
        </div>
      </section>

      <section className="py-10">
        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              You Are Safe Online is easy to understand.
            </h2>
            <p className={paragraphClassName}>
              All the articles are written specifically for non-technical
              people. I dropped all of the useless jargon like "256-bit private
              key". Who cares about these details, anyway? You want to know if a
              software is safe enough to store your photos, you don't need a
              whitepaper.
            </p>
            <p className={paragraphClassName}>
              Here's a snippet from{' '}
              <Link to="/blog/can-you-see-old-facebook-passwords">
                "Is There A Way to See Old Facebook Passwords?"
              </Link>
              : ...Generally, Facebook will not share your old passwords because
              they store them in a format that is unreadable...
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              You Are Safe Online has specific articles, formatted to find the
              answer in seconds.
            </h2>
            <p className={paragraphClassName}>
              You will find articles covering very specific issues, like:{' '}
              <Link to="/blog/password-safety-for-kids">
                "A Quick Guide to Password Safety for Kids"
              </Link>{' '}
              and{' '}
              <Link to="/blog/how-to-track-a-fake-instagram-account">
                "How to Track a Fake Instagram Account: Parents Edition"
              </Link>
              . Also, all of the articles are structured like this: answer first
              and explanation later. This allows you to read the answer and
              leave if you are in a hurry, or stay and read the details below if
              you choose to. No more aimless searching through lengthy articles.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              You Are Safe Online is specialized in internet safety.
            </h2>
            <p className={paragraphClassName}>
              This is not a generic computer blog. You will only find articles
              about internet safety here. No spam, no random information that
              you don't need. You will find titles like:{' '}
              <Link to="/blog/add-parental-controls-on-a-samsung-tablet/">
                "7 Steps to Adding Parental Controls on a Samsung Tablet"
              </Link>{' '}
              or{' '}
              <Link to="/blog/11-safest-free-email-accounts/">
                "11 Safest Free Email Accounts"
              </Link>
              .
            </p>
          </div>
        </div>
      </section>

      <section className="bg-primary-100 py-10">
        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              You Are Safe Online is regularly updated.
            </h2>
            <p className={paragraphClassName}>
              I am constantly searching for new topics to cover. This way I
              bring new and fresh articles to you regularly.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              Searching in You Are Safe Online is better than searching on
              Google.
            </h2>
            <p className={paragraphClassName}>
              When you search on Google,{' '}
              <span className="whitespace-no-wrap">1 result = 1 article</span>.
              On You Are Safe Online,{' '}
              <span className="whitespace-no-wrap">1 result = 1 chapter</span>{' '}
              or section from an article. This way you can easily find what you
              are looking for, without wasting time.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              You don't need any computer skills.
            </h2>
            <p className={paragraphClassName}>
              If you know how to search on Google, you know how to find all the
              advice you need on You Are Safe Online.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>
              Articles are written by experts.
            </h2>
            <p className={paragraphClassName}>
              All the resources you find here are written by experts and
              double-checked by me. I will make sure that only the best and most
              accurate content gets published.
            </p>
          </div>
        </div>

        <div className={pointClassName}>
          <div className={containerClassName}>
            <h2 className={headerClassName}>You Are Safe Online is free.</h2>
            <p className={paragraphClassName}>
              There is no cost associated with these resources. You can search,
              read, browse how much you like, no limit. There are no intrusive
              ads, annoying popups, or unskippable videos. If you want to know
              how I make money from this, you can read about it on the ''Support
              me" page.
            </p>
          </div>
        </div>
      </section>

      <section className={clsx(pointClassName, 'h-screen')}>
        <div className={containerClassName}>
          <div className="flex-1 flex flex-col justify-center">
            <h2 className={headerClassName}>Try it now!</h2>
            <p className={paragraphClassName}>
              Just search for what is bothering you, and you'll get your answer
              in no time. Try:{' '}
              <Link
                to="/search/?query=password safety for kids"
                className="no-underline"
              >
                "password safety for kids"
              </Link>
              .
            </p>
            <div className="flex items-end mt-8 w-full">
              <NavigateToSearch className="w-full" />
            </div>
          </div>
          <h1 className="text-black">YOU Are Safe Online</h1>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ForBusyParentsQuery {
    authorImage: file(relativePath: { eq: "team/bogdan-bota.jpg" }) {
      childImageSharp {
        fixed(width: 200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    busyParentImage: file(
      relativePath: { eq: "lp/alexander-dummer-UH-xs-FizTk-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    personUsingGoogleImage: file(
      relativePath: { eq: "lp/benjamin-dada-EDZTb2SQ6j0-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    happyPersonImage: file(
      relativePath: { eq: "lp/bruce-mars-AndE50aaHn4-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    peopleStudyingImage: file(
      relativePath: { eq: "lp/scott-graham-5fNmWej4tAA-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default YSOForBusyParents;
